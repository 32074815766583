import React from 'react'
import Layout from '../../components/Layout'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Block from '../../components/Block'
import { useLocation } from '@gatsbyjs/reach-router'
import queryString from 'query-string'

const NoAuthPage = () => {
  const location = useLocation()
  const { error } = queryString.parse(location.search)

  return (
    <Layout
      justifyCenter
      title="Unauthenticated"
      description="No authentication found"
    >
      <Wrapper>
        <Block size="small">
          <h1>🤖 We couldn&#39;t log you in</h1>
          <ErrorDescription
            errorCode={typeof error === 'string' ? error : undefined}
          />
          <LinkWrapper>
            <Link to="https://app.swarmia.com">
              <button className="big">Log in</button>
            </Link>
            <Link to="https://app.swarmia.com/signup/">
              <button className="primary big">Get started now</button>
            </Link>
          </LinkWrapper>
        </Block>
      </Wrapper>
    </Layout>
  )
}

function LetUsKnowLink() {
  return <a href="mailto:hello@swarmia.com">let us know</a>
}

function ErrorDescription(props: { errorCode: string | undefined }) {
  switch (props.errorCode) {
    case 'Unexpected':
      return (
        <p>
          There was an error logging you in, please try again later. If the
          issue persists, <LetUsKnowLink />.
        </p>
      )
    case 'NoAccess':
      return (
        <p>
          Looks like you don&apos;t have access to one of the organizations
          you&apos;re trying to log in to. If you think this is a mistake,{' '}
          <LetUsKnowLink />.
        </p>
      )

    default:
      return (
        <p>
          Your organization might not be using Swarmia yet. If that&apos;s the
          case, <a href="https://app.swarmia.com/signup/">get started here</a>.
          If you think there&apos;s was a mistake, <LetUsKnowLink />.
        </p>
      )
  }
}

export default NoAuthPage

const LinkWrapper = styled.div`
  margin: 24px 0 48px;
  a {
    margin: 0 6px;
  }
`

const Wrapper = styled.div`
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 100px 20px 120px 20px;
`
